import { useTranslation } from "react-i18next";
import quyi from "../../assets/images/quyi.jpg";

const RegionInfo = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="flex justify-center items-center px-[100px] gap-8 h-[330px]">
        <div className="w-[600px]">
          <img src={quyi} alt="" />
        </div>
        <div className="w-[865px] h-[170px]">
          <h4 className="font-[700] mb-[10px] mt-[-50px] text-[36px] text-#121221">
            {t("Quyi Chirchiq tumani haqida")}
          </h4>
          <p>
            <strong className="text-[20px]">Quyi Chirchiq tumani</strong> -
             tashkil topgan sana 29- sentyabr 1926-yil Hududi (yer maydoni)
            - 557,17 kv.km, Chegara uzunligi - 143,3 km, Sirdaryo viloyati bilan
            - 18 km, Chinoz tumani bilan - 27.9 km, Yangiyo‘l tumani bilan - 36
            km, Oqqo‘rg‘on tumani bilan - 28.8 km, Yangixayot tumani bilan -
            25.8 km 2.3 km, Aholi soni (01.01.2023y.) - 114.2 ming kishi.
            Tumanga bo‘ysunuvchi shaharchalar - 1 ta Shaharchalar (shahar
            tipidagi posyolkalar) - 2 ta, Aholi punktlari - 60 ta, Mahalla
            fuqarolar yig‘inlari - 37 ta, Xonadonlar soni - 21782 ta Oilalar
            soni - 27228 ta
          </p>
        </div>
      </div>
    </div>
  );
};

export default RegionInfo;
