import { useForm } from "react-hook-form";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { cities } from "../../constants";
import { formSchemaAnti } from "../../lib/validation";
import { zodResolver } from '@hookform/resolvers/zod';

const SendMessageAnti = () => {
    const { t } = useTranslation();
    const notify = () => toast.success("Murojaatingiz yuborildi !");
    let navigate = useNavigate();
    const [rhemail, setRhemail] = useState("antikorrupsiya@toshkentviloyati.uz");
    const [selectedFilePdf, setSelectedFilePdf] = useState(null);
    const PdfInputRef = useRef(null);

    const handlePdfChange = (e) => {
        setSelectedFilePdf(e.target.files[0]);
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: zodResolver(formSchemaAnti),
    });

    const onSubmit = async (data) => {
        try {
            const formData = new FormData();
            formData.append("file", selectedFilePdf);
            formData.append("subject", data.subject);
            formData.append("send_email", data.send_email);
            formData.append("phone", data.phone);
            formData.append("address", data.address);
            formData.append("message", data.message);
            formData.append("email", rhemail);

            const response = await axios.post(
                "https://admin.toshvil.uz/ru/contact/sendmail/",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            if (response) {
                notify();
                navigate("/");
            } else {
                console.error("Failed to send message");
            }
        } catch (error) {
            console.error("Error sending message:", error);
        }
    };

    return (
        <div className="md:mx-0 mx-6">
            <h2 className="text-center text-2xl">
                {t(
                    "Korrupsiya yoki manfaatlar to‘qnashuvi masalalariga oid murojaat yuborish"
                )}
            </h2>
            <p className="text-md lg:mx-16 text-center my-6">
                {t(
                    "O‘zbekiston Respublikasi Prezidentining 2021-yil 6-iyuldagi “Korrupsiyaga qarshi murosasiz munosabatda bo‘lish muhitini yaratish, davlat va jamiyat boshqaruvida korrupsiyaviy omillarni keskin kamaytirish va bunda jamoatchilik ishtirokini kengaytirish chora-tadbirlari to‘g‘risida”gi PF-6257-sonli Farmoni, “Korrupsiyaga qarshi kurashish faoliyatini samarali tashkil etishga doir qo‘shimcha chora-tadbirlar to‘g‘risida”dagi PQ-5177-sonli qarori hamda O‘zbekiston Respublikasi korrupsiyaga qarshi kurashish agentligining 2021-yil 10-sentyabrdagi 03-07/6208-sonli xatiga muvofiq Toshkent viloyati hokimligi Korrupsiyaga qarshi kurashish ichki nazorat sho‘basi tashqil qilingan."
                )}
            </p>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="gap-4 flex flex-col lg:mx-[300px] md:mx-[50px]"
            >
                <div className="flex flex-col gap-2">
                    <label htmlFor="Ismi">{t("F.I.SH.")}</label>
                    <input
                        {...register("subject")}
                        type="string"
                        placeholder="F.I.SH. kiriting"
                        className="px-4 py-2 rounded bg-neutral-100"
                        autoComplete="off"
                    />
                    {errors.subject && (
                        <p className="text-red-500">{errors.subject.message}</p>
                    )}
                </div>
                <div className="flex flex-col gap-2">
                    <label htmlFor="Elektron manzil">{t("Elektron manzil")}</label>
                    <input
                        {...register("send_email")}
                        type="email"
                        placeholder="Email manzilingizni kiriting..."
                        className="px-4 py-2 rounded  bg-neutral-100"
                    />
                    {errors.send_email && (
                        <p className="text-red-500">{errors.send_email.message}</p>
                    )}
                </div>
                <div className="flex flex-col gap-2">
                    <label htmlFor="Tel raqam">{t("Telefon raqam")}</label>
                    <input
                        {...register("phone")}
                        type="number"
                        placeholder="Telefon raqamingizni kiriting kiriting..."
                        className="px-4 py-2 rounded  bg-neutral-100"
                    />
                    {errors.phone && (
                        <p className="text-red-500">{errors.phone.message}</p>
                    )}
                </div>
                <div className="flex flex-col gap-2">
                    <label htmlFor="address">{t("Shahar va tuman")}</label>
                    <select
                        className="px-4 py-2 rounded  bg-neutral-100"
                        {...register("address")}
                    >
                        <option value="">{t("Shahar yoki tuman tanlang")}</option>
                        {cities.map((item, index) => (
                            <option key={index} value={item.title}>
                                {item.title}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="flex flex-col gap-2">
                    <label htmlFor=" Murojatnoma">{t("Murojatnoma mazmuni")}</label>
                    <textarea
                        {...register("message")}
                        className="px-4 py-2 h-24 rounded bg-neutral-100"
                        id="Murojatnoma"
                        placeholder="Murojatingizni kiriting..."
                    />
                    {errors.message && (
                        <p className="text-red-500">{errors.message.message}</p>
                    )}
                </div>

                <div>
                    <input
                        className="hidden"
                        type="email"
                        value={rhemail}
                        onChange={(e) => setRhemail(e.target.value)}
                    />
                </div>
                <div>
                    <input
                        ref={PdfInputRef}
                        type="file"
                        accept=".pdf, .jpeg, .jpg"
                        onChange={handlePdfChange}
                        className="text-lg"
                    />
                </div>
                <button
                    onClick={notify}
                    type="submit"
                    className="bg-[#014f86] p-3  rounded text-white mt-4 md:mb-24 mb-12"
                >
                    {t("Murojaat yuborish")}
                </button>
            </form>
        </div>
    );
};

export default SendMessageAnti;
