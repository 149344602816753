import {
  Navigate,
  useLocation,
  useParams,
  useNavigate,
} from "react-router-dom";
import { useGetNavDetail } from "../../../../hooks/query";
import NavbarDetailItem from "./NavbarDetailItem";
import { TiArrowBack } from "react-icons/ti";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const NavbarDetail = () => {
  const { navid } = useParams();
  const navigate = useNavigate();
  const [slug, setSlug] = useState("/ndetail/murojaat-yuborish");

  const { t } = useTranslation();
  const useGetNavDetailList = useGetNavDetail({
    id: navid,
  });

  const location = useLocation();
  if (location.pathname == slug) {
    return <Navigate to="/ak/murojaat-yuborish" />;
  }

  return (
    <div>
      <button
        onClick={() => navigate(-1)}
        className="lg:flex md:flex hidden items-center gap-1 bg-[#0C356A] text-white pr-4 px-3 rounded py-1 hover:bg-[#0174BE] transition-all duration-200 mb-8 lg:ml-0 md:ml-4"
      >
        <TiArrowBack size={26} />
        {t("ortga")}
      </button>
      {useGetNavDetailList.data?.results.map((item) => {
        return (
          <NavbarDetailItem
            slug={slug}
            setSlug={setSlug}
            item={item}
            key={item.id}
          />
        );
      })}
      {useGetNavDetailList.data?.results == 0 && (
        <p className="text-center text-red-500 my-[200px] font-semibold text-2xl">
          {t("Ma'lumot mavjud emas")}
        </p>
      )}
    </div>
  );
};
export default NavbarDetail;
