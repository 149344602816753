import React from "react";
import gerb from "../../../assets/images/gerb.png";
import karta from "../../../assets/images/kart.png";
import ScrollToTop from "./scrollToTop/ScrollToTop";
import { FiInstagram, FiYoutube } from "react-icons/fi";
import { FaTelegramPlane } from "react-icons/fa";
import { BsFacebook } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="lg:h-[400px] md:h-[400px] bg-[#3b477f] text-white">
      <div className="max-w-[1280px] mx-auto lg:flex md:flex justify-between pt-4 lg:px-0 md:px-3 px-4">
        <div className="lg:w-[650px] md:w-[600px] flex flex-col gap-4 lg:text-[17px] md:text-[16px]">
          <div className="flex gap-2 items-center pt-[6px]">
            <img src={gerb} alt="gerb" width="50px" height="50px" />
            <h2 className="text-xl font-semibold text-white">
              {t("Quyi Chirchiq tumani hokimligi")}
            </h2>
          </div>
          <p className="lg:w-[600px]">
            {t(
              "Sayt Toshkent viloyati hokimligi huzuridagi, Axborot kommunikatsiya texnologiyalarini rivojlantirish markazi mutaxassislari tomonidan yaratilgan © 2023"
            )}
          </p>
          <p className="lg:w-[550px]">
            {t(
              "Ushbu sayt materiallaridan foydalanganda www.quyichirchiq.uz saytiga havola qilish majburiydir"
            )}
          </p>
          <a className="hover:text-gray-400 duration-300" href="/saytxaritasi">
            {t("Sayt xaritasi")}
          </a>
          <div className="flex gap-6 mt-8">
            <a
              className="hover:text-[#963f9d] duration-300"
              href="https://www.instagram.com/quyichirchiqhokimligi/"
              target="_blank"
              rel="noreferrer"
            >
              <FiInstagram size={26} />
            </a>
            <a
              className="hover:text-[#CD201F] duration-300"
              href="https://www.youtube.com/channel/UCdvoGNwObrWDa-nx5T6l-Qw"
              target="_blank"
              rel="noreferrer"
            >
              <FiYoutube size={29} />
            </a>
            <a
              className="hover:text-[#0088cc] duration-300"
              href="https://t.me/quyi_chirchiq_pressa"
              target="_blank"
              rel="noreferrer"
            >
              <FaTelegramPlane size={28} />
            </a>
            <a
              className="hover:text-[#3b5998] duration-300"
              href="https://www.facebook.com/people/Quyi-Chirchiq-Hokimligi/pfbid02usexN3szHKSAzmFDz7QNvi2kZHaoZDEQpV2rGn4TNySndU5qvXSSfLXi8emXBTxsl/?mibextid=ZbWKwL"
              target="_blank"
              rel="noreferrer"
            >
              <BsFacebook size={26} />
            </a>
          </div>
        </div>
        <div className="flex flex-col gap-2 mt-[10px]">
          <p>{t("Bizning manzil:")}</p>
          <p>{t("Navqiron ko'chasi, 1-uy")}</p>
          <a
            href="https://www.google.com/maps/place/40%C2%B051'54.1%22N+68%C2%B056'11.2%22E/@40.865028,68.936453,16z/data=!4m4!3m3!8m2!3d40.865028!4d68.936453?entry=ttu"
            target="_blank"
            rel="noreferrer"
            className="overflow-hidden bg-no-repeat bg-cover"
          >
            <img
              src={karta}
              alt="harita"
              a
              width="400px"
              height="400px"
              className="hover:scale-125 object-cover transition duration-700 ease-in-out"
            />
          </a>
          <p>
            {t("Tel: +998 70 567 21 01")} <br />
          </p>
          <p>{t("Murojaat qilish vaqti: 9:00 / 18:00")}</p>
        </div>
      </div>

      <div className="lg:block md:block hidden">
        <ScrollToTop />
      </div>
    </div>
  );
};

export default Footer;
